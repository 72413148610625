import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const HowItWorksMob = () => {
  return (
    <div className="bg-white px-6 py-6">
      <div className="columns-1">
        <div>
          <p className="text-base font-semibold leading-7 text-primary">
            Our Partners Card
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
            How it Works
          </h1>
          <p className="mt-6 w-max-sm text-xl justify-evenly leading-8 text-cool-gray-500">
            You can now enjoy exclusive discounts offered to our partners'
            network. One time registration process is required.
          </p>
        </div>
        <div>
          <h1 className="mt-4 mb-12 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
            Step 1
          </h1>
        </div>
        <div className="-ml-32 -mt-44 p-8">
          <img
            alt=""
            src="../../images/promotions/mob/mobLoginPage.png"
            className="scale-75 justify-start  justify-items-start max-w-sm rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
          />
        </div>
        <div>
          <h1 className="mt-4 mb-12 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
            Step 2
          </h1>
        </div>
        <div className="-ml-28 -mt-44 p-8">
          <img
            alt=""
            src="../../images/promotions/mob/mobLeftMenu.png"
            className="scale-75  justify-start justify-items-start max-w-sm rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
          />
        </div>
        <div>
          <h1 className="mt-4 mb-12 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
            Step 3
          </h1>
        </div>
        <div className="-ml-28 -mt-44 p-8">
          <img
            alt=""
            src="../../images/promotions/mob/mobUploadCard.png"
            className="scale-75  justify-start justify-items-start max-w-sm rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
          />
        </div>
        <div>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
            After Upload
          </h1>
          <ul role="list" className="mt-8 space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <LockClosedIcon
                aria-hidden="true"
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  After upload the document
                </strong>{" "}
                <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                  Once you receive the success message, provide us 24 hours to
                  apply the discounted rate on your profile.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksMob;
