import { createContext, useEffect, useState, useContext } from "react";
import {
  getProducts,
  getProductsByCustomerGroup,
  PromoProductsByToken,
} from "../utils/ProductsApi";
// import ProductsData from "../../src/utils/Data/ProductsData.json";

export const ProductsContext = createContext({
  products: [],
  getMyProduts: () => null,
  getMyProductsByCustomerGroup: () => null,
  promoCodeApplied: false,
});

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getMyProduts();
  }, []);

  const getMyProduts = async () => {
    // ProductsAPI()
    //   .getProduts()
    //   .then((data) => {
    //     setProducts(data);
    //   });

    const products = await getProducts();
    setProducts(products);
  };

  const getMyProductsByCustomerGroup = async (customerToken) => {
    // console.log("customerToken from Context", customerToken);
    // const products = await getProductsByCustomerGroup(customerToken);
    // console.log("products from Context");
    // console.log(products);
    const products = await PromoProductsByToken(customerToken);
    console.log(products);

    setProducts(products.data);
  };

  const CalcualtePriceAfterUpdate = async (mProducts, mCartItems) => {
    console.log("Getting Cart Items");

    console.log(mCartItems);

    // const updatedProducts = mProducts.map((product) => {
    //   if (product.id === mCartItems.id) {
    //     //product.quantity = updatedQuantity;
    //     //product.totalPrice = product.price * updatedQuantity;
    //     mCartItems.price = product.price;
    //     mCartItems.totalPrice = product.price * mCartItems.quantity;

    //     console.log("**** CartItems from Cart ****");
    //     console.log(mCartItems);
    //   }

    //   return product;
    // });
  };

  const reCountProductsQuantity = (productToRemove) => {
    //console.log(productToRemove.id);
    const existingItem = products.find(
      (item) => item.id === productToRemove.id
    );

    existingItem.quantity = 0;
  };

  const reduceQuantity = (productToDecrease) => {
    const existingItem = products.find(
      (item) => item.id === productToDecrease.id
    );

    existingItem.quantity = existingItem.quantity - 1;
  };

  const increaseQuantity = (productToIncrease) => {
    const existingItem = products.find(
      (item) => item.id === productToIncrease.id
    );

    existingItem.quantity = existingItem.quantity + 1;
  };

  const value = {
    products,
    getMyProduts,
    getMyProductsByCustomerGroup,
    reCountProductsQuantity,
    increaseQuantity,
    reduceQuantity,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
