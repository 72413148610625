import React from "react";

import { Routes, Route } from "react-router-dom";

import NavbarUI from "./components/navbar/NavbarUI";
import logo from "./images/ZulalLogo.svg";
import { Home } from "./spa/Home";
import { AboutZulal } from "./spa/about/AboutZulal";
import { Promotions } from "./spa/promotions/Promotions";
import { HowItWorks } from "./spa/promotions/HowItWorks";
import { Zulal } from "./spa/zulal/Zulal";
import { UserLogin } from "./spa/user/UserLogin";
import { Page404 } from "./spa/Page404";
import { ComingSoon } from "./spa/ComingSoon";
import { Contactus } from "./spa/contactus/contactus";
import { UserHome } from "./spa/user/UserHome";

import Footer from "./spa/footer/Footer";

import { UserLayout } from "./layouts/UserLayout";
import { UserProfile1 } from "./spa/user/UserProfile1";
import { Security } from "./spa/user/Security";

import { UserInvoices } from "./spa/user/UserInvoices";
import { Orders } from "./spa/user/Orders";
import { Logout } from "./spa/user/Logout";
import { ZulalCredits } from "./spa/user/ZulalCredits";
import { UserAddress } from "./spa/user/UserAddress";
import { UserPrivateRoutes } from "./utils/UserPrivateRoutes";

import { Dashboard } from "./spa/user/Dashboard";
import CreditCardPage from "./spa/user/CreditCardPage";

import { UserDetails } from "./spa/user/UserDetails";
import UserAddressUpdated from "./spa/user/UserAddressUpdated";

import CreateUser from "./spa/user/CreateUser";

import { TopUpConfirmation } from "./spa/user/TopUpConfirmation";
import NewsAndMediaPage from "./spa/newsandmedia/NewsAndMediaPage";
import { ProductsRange } from "./spa/shop/ProductsRange";
import { CheckOut } from "./spa/shop/CheckOut";
import { OrderConfirmation } from "./spa/user/OrderConfirmation";
import { RefundPolicy } from "./spa/RefundPolicy";
import LoyaltyCard from "./spa/user/LoyaltyCard";
import OrderTracking from "./spa/userOrderTracking/OrderTracking";
import MobilePage from "./spa/mob/mobpromo";
import HowItWorksMob from "./spa/promotions/HowItWorksMob";

function App() {
  // Layout with Header
  const WithHeaderFooterLayout = ({ children }) => (
    <>
      <NavbarUI logo={logo} />
      {children}
      <Footer />
    </>
  );

  // Layout without Header
  const WithoutHeaderFooterLayout = ({ children }) => <>{children}</>;

  return (
    <React.Fragment>
      <Routes>
        {/* Routes with Header and Footer */}
        <Route
          path="/"
          element={
            <WithHeaderFooterLayout>
              <Home />
            </WithHeaderFooterLayout>
          }
        />
        {/* About us Page */}
        <Route
          path="about"
          element={
            <WithHeaderFooterLayout>
              <AboutZulal />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="zulal"
          element={
            <WithHeaderFooterLayout>
              <Zulal />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="productsrange"
          element={
            <WithHeaderFooterLayout>
              <ProductsRange />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="NewsMedia"
          element={
            <WithHeaderFooterLayout>
              <ComingSoon />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="ContactUs"
          element={
            <WithHeaderFooterLayout>
              <Contactus />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="ComingSoon"
          element={
            <WithHeaderFooterLayout>
              <ComingSoon />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="topupconfirmation"
          element={
            <WithHeaderFooterLayout>
              <TopUpConfirmation />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="orderconfirmation"
          element={
            <WithHeaderFooterLayout>
              <OrderConfirmation />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="promotions"
          element={
            <WithHeaderFooterLayout>
              <Promotions />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="howitworks"
          element={
            <WithHeaderFooterLayout>
              <HowItWorks />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="orderTracking"
          element={
            <WithHeaderFooterLayout>
              <OrderTracking />
            </WithHeaderFooterLayout>
          }
        />

        <Route
          path="login"
          element={
            <WithHeaderFooterLayout>
              <UserLogin />
            </WithHeaderFooterLayout>
          }
        />
        <Route
          path="createuser"
          element={
            <WithHeaderFooterLayout>
              <CreateUser />
            </WithHeaderFooterLayout>
          }
        />

        <Route
          path="refundpolicy"
          element={
            <WithHeaderFooterLayout>
              <RefundPolicy />
            </WithHeaderFooterLayout>
          }
        />

        {/* Protected Routes */}

        {/* Protected Routes without Header */}
        <Route element={<UserPrivateRoutes />}>
          <Route element={<WithHeaderFooterLayout />}>
            <Route path="/user" element={<UserLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="userdetails" element={<UserDetails />} />
              <Route path="profile" element={<UserProfile1 />} />
              <Route path="address-1" element={<UserAddress />} />
              <Route path="address" element={<UserAddressUpdated />} />
              <Route path="security" element={<Security />} />
              <Route path="invoices" element={<UserInvoices />} />
              <Route path="zulalwallet" element={<ZulalCredits />} />
              <Route path="partnerscard" element={<LoyaltyCard />} />
              <Route path="orders" element={<Orders />} />
              <Route path="CreditCardPayment" element={<CreditCardPage />} />
              <Route path="dashboard" element={<UserHome />} />
              <Route path="checkout" element={<CheckOut />} />
              <Route path="logout" element={<Logout />} />
            </Route>
          </Route>
        </Route>
        {/* Routes with without Header and Footer */}

        <Route
          path="PromotionMob"
          element={
            <WithoutHeaderFooterLayout>
              <Promotions qry="mob" />
            </WithoutHeaderFooterLayout>
          }
        />

        <Route
          path="HowItWorksMob"
          element={
            <WithoutHeaderFooterLayout>
              <HowItWorksMob qry="mob" />
            </WithoutHeaderFooterLayout>
          }
        />

        {/* Catch-all route */}

        <Route
          path="*"
          element={
            <WithHeaderFooterLayout>
              <Page404 />
            </WithHeaderFooterLayout>
          }
        />
      </Routes>
    </React.Fragment>
  );
}

export default App;
