import React from "react";
import { Breadcrumbs } from "../../components/navbar/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const people = [
  {
    name: "SEWA Card",
    role: "Promotional Partner",
    imageUrl: "../../images/sliderimages/sewa_logo_2024.png",
    bio: "Sharjah Electricity, Water and Gas Authority launched a special offer for its employees, an exclusive discount on Zulal products. This initiative comes as part of the Authority’s efforts to enhance employee satisfaction and raise their quality of life, expressing its appreciation for their efforts by providing them with the best offers.",
    xUrl: "https://www.sewa.gov.ae/",
  },
  {
    name: "Essad Card - 20%",
    role: "Promotional Partner",
    imageUrl: "../../images/sliderimages/Esaad_logo.png",
    bio: "General Directorate of Dubai Police lanuches ESAAD Card, which is a creative tool to enhance Employees’ Happiness and aims to put a smile on their faces. It aims to improve quality of life and show appreciation to our employees by providing them with the best offers and discounts.",
    xUrl: "https://esaad.dubaipolice.gov.ae/",
  },
  {
    name: "Faaza Card - 20%",
    role: "Promotional Partner",
    imageUrl: "../../images/sliderimages/faaza_logo.png",
    bio: "Fazaa offers a range of exclusive services to employees of the Ministry of the Interior, government and semi-governmental entities, which signed a memorandum of understanding between Fazaa and the entity.",
    xUrl: "https://www.fazaa.ae/",
  },
  {
    name: "Waffer Card - 20%",
    role: "Promotional Partner",
    imageUrl: "../../images/sliderimages/Waffer_logo_1.png",
    bio: "The primary objective of the Waffer Card is to boost the affiliation of Sharjah government employees with their respective employers and institutions, by providing them with a range of incentives and benefits that help them tackle the challenges of everyday life. Additionally, it aims to reinforce partnerships with the private sector establishments by allowing them to offer exclusive discounts and privileges to Waffer Card holders.",
    xUrl: "https://waffer.dhr.gov.ae/",
  },

  // More people...
];

export const Promotions = ({ qry }) => {
  const qryParams = qry;

  //console.log(`requesting qry: ${qry}`);

  const queryParams = new URLSearchParams(window.location.search);

  const sourcePage = queryParams.get("qry");

  //console.log(queryParams);

  return (
    <div className="bg-white py-8 md:py-16">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            About Our Promotions
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            You can now enjoy exclusive discounts offered to our partners'
            network. One time registration process is required.
          </p>
          see
          {sourcePage === "mob" ? (
            <Link
              to="/HowItWorksmob"
              className="text-base text-secondary hover:text-secondaryHover"
            >
              {" "}
              how it works{" "}
            </Link>
          ) : (
            <Link
              to="/howitworks"
              className="text-base text-secondary hover:text-secondaryHover"
            >
              {" "}
              how it works{" "}
            </Link>
          )}
        </div>
        <ul
          role="list"
          className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3"
        >
          {people.map((person) => (
            <li
              key={person.name}
              className="flex flex-col gap-10 sm:flex-row pt-12"
            >
              <img
                alt=""
                src={person.imageUrl}
                className="flex-none rounded-2xl object-contain h-40 "
              />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-cool-gray-800">
                  {person.name}
                </h3>
                <p className="text-base leading-7 text-cool-gray-600">
                  {person.role}
                </p>
                <p className="mt-6 text-base leading-7 text-cool-gray-500">
                  {person.bio}
                </p>

                <ul role="list" className="mt-6 flex gap-x-6">
                  <li>
                    <Link
                      target="_blank"
                      to={person.xUrl}
                      className="mt-2 text-base tracking-tight text-secondary hover:text-secondaryHover"
                    >
                      for more info{" "}
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Link>
                    <p className="mt-2 text-base font-poppins tracking-tight text-secondary"></p>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
