import React, { useContext } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Breadcrumbs } from "../components/navbar/Breadcrumbs";

import {
  UilCreateDashboard,
  UilUser,
  UilShieldCheck,
  UilUserLocation,
  UilShoppingCart,
  UilInvoice,
  UilWallet,
  UilSignout,
  UilCreditCard,
} from "@iconscout/react-unicons";

import { UserContext } from "../context/user.context";

import NavData from "../utils/Data/UserNavData.json";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const UserLayout = (props) => {
  const { currentUser } = useContext(UserContext);
  const loc = useLocation();

  return (
    <div className="">
      <header>
        <div className="bg-pagePattern bg-center">
          <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center h-32  ">
            <label className="text-7xl text-textWhite font-medium  ">
              {loc.state.PageTitle === null
                ? "Missing Title"
                : loc.state.PageTitle}
            </label>
          </div>
        </div>
      </header>

      <main className="flex flex-col mb-28 mx-auto max-w-screen-lg lg:pl-5 lg:pr-5">
        <div className="flex flex-col md:flex-row mt-4 gap-5">
          <div
            className="hidden md:flex flex-col p-4 w-64 border border-navbarBorder bg-navbarBackground rounded-lg
                          justify-start items-center h-auto"
          >
            <label className="font-medium font-poppins text-base text-navarbarText text-center">
              Welcome {!currentUser.isloggedIn ? "" : currentUser.outletName}
            </label>

            <div className="w-[188px] h-[1px] mt-4 bg-secondaryHover text-center"></div>

            <div className="flex flex-col gap-4 mt-4 justify-start items-start w-52 ">
              {NavData.map((item) => {
                return (
                  item.active && (
                    <NavLink
                      to={`../user/${item.path}`}
                      state={{ PageTitle: item.title }}
                      className={classNames(
                        item.active ? "text-primaryHover" : "text-primary",
                        "flex flex-row items-center px-4 py-1 text-base gap-2 font-poppins hover:text-secondary"
                      )}
                    >
                      {item.title === "Dashboard" ? (
                        <UilCreateDashboard className="text-primaryHover" />
                      ) : item.title === "Profile" ? (
                        <UilUser className="text-primaryHover" />
                      ) : item.title === "Address" ? (
                        <UilUserLocation className="text-primaryHover" />
                      ) : item.title === "Security" ? (
                        <UilShieldCheck className="text-primaryHover" />
                      ) : item.title === "Zulal Wallet" ? (
                        <UilWallet className="text-primaryHover" />
                      ) : item.title === "Orders" ? (
                        <UilShoppingCart className="text-primaryHover" />
                      ) : item.title === "Invoices" ? (
                        <UilInvoice className="text-primaryHover" />
                      ) : item.title === "Partners Card" ? (
                        <UilCreditCard className="text-primaryHover" />
                      ) : item.title === "Log Out" ? (
                        <UilSignout className="text-primary" />
                      ) : (
                        ""
                      )}
                      {item.title}
                    </NavLink>
                  )
                );
              })}
            </div>
          </div>
          <div className="items-start w-full">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
};
