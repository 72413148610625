import axios from "axios";
import { Products_API_URL, GetSiteUpdatedProducts_API_URL } from "./config";

// export const ProductsAPI = () => {
//   const env_var = process.env.NODE_ENV;

//   return {
//     getProduts: (req, res) =>
//       axios
//         .get(Products_API_URL)
//         .then((response) => {
//           res = response.data;
//           return res;
//         })
//         .catch((err) => {
//           if (env_var === "production") {
//             console.log("Get Products Error: ");
//           } else {
//             console.log("Get Products Error: " + err.message);
//           }
//         }),
//   };
// };

export const getProducts = async () => {
  const env_var = process.env.NODE_ENV;

  const myproducts = await axios.get(Products_API_URL);

  if (myproducts.status !== 200) {
    throw new Error(`Failed to fetch products: ${myproducts.status}`);
  }

  if (env_var === "development") {
    console.log("Development mode enabled");
    console.log(myproducts.data);
  }

  return myproducts.data;
};

export const getProductsByCustomerGroup = async (token) => {
  if (!token) {
    throw new Error("Invalid token");
  }

  const products = await getProducts();

  // console.log("products API returned");
  // console.log(products);
  // console.log("products API returned");

  const promoProductsResponse = await axios.get(
    GetSiteUpdatedProducts_API_URL,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // console.log("products API returned");
  // console.log(promoProductsResponse);
  // console.log("products API returned");

  if (promoProductsResponse.status !== 200) {
    // throw new Error(
    //   `Failed to fetch promo products: ${promoProductsResponse.status}`
    // );
    return {
      status: promoProductsResponse.status,
      message: "Failed to fetch promo products",
      data: [],
    };
  }

  if (promoProductsResponse.data.length <= 0) {
    //throw new Error("Unexpected response format: data is not an array");
    return {
      status: promoProductsResponse.status,
      message: "Unexpected response format: data is not an array",
      data: [],
    };
  }

  const promoProductsData = promoProductsResponse.data.data.itemDetails;

  const updatedProducts = products.map((product) => {
    const matchedProduct = promoProductsData.find(
      (p) => p.itemCode == product.id.toString()
    );

    if (matchedProduct) {
      //console.log(matchedProduct.itemCode + " " + matchedProduct.price);
      // Update the product's price
      return {
        ...product,
        discount: 0,
        promoPrice: true,
        price: matchedProduct.price + " AED",
      };
    }
  });

  return updatedProducts;
};

export const PromoProductsByToken = async (token) => {
  const customerToken = token;
  //console.log(customerToken);

  const promoProductsDataResponse = await axios.get(
    GetSiteUpdatedProducts_API_URL,
    {
      headers: {
        Authorization: `Bearer ${customerToken}`,
      },
    }
  );
  //console.log(promoProductsDataResponse);

  return promoProductsDataResponse;
};
