import React, { useEffect } from "react";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UilShoppingCart } from "@iconscout/react-unicons";

import { OrdersAPI } from "../../utils/OrdersAPI";
import UserTrackingMap from "./UserTrackingMap";

// const products = [
//   {
//     id: 1,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 2,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 3,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 4,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },

//   // More products...
// ];

const OrderTracking = () => {
  //query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("ref");
  const [orderDetails, setOrderDetails] = useState(null);
  const [products, setProducts] = useState(null);
  const [orderStatus, setOrderStatus] = useState("NA");
  const [orderDate, setOrderDate] = useState(null);
  const [orderScheduleDate, setOrderScheduleDate] = useState(null);
  const [actualDeliveryDate, setActualDeliveryDate] = useState(null);

  const [trackDetails, setTrackDetails] = useState({});

  //console.log(`first order ${orderId}`);

  function getLastNCharacters(str, n) {
    const retString = str.substr(Math.max(str.length - n, 0));
    return retString.toUpperCase();
  }

  const [customerAddress, setCustomerAddress] = useState({});
  // let CustomerAddress = {
  //   BuildingName: "",
  //   FlatNumber: "",
  //   LandMark: "",
  //   Address: "",
  // };

  const [orderSummary, setOrderSummary] = useState({});

  function formatDateToMMDDYYYY(dateString) {
    // if (!(date instanceof Date) || isNaN(date)) {
    //   console.error("Invalid Date provided");
    //   alert("Invalid Date provided");
    //   return null; // Handle invalid date input
    // }

    if (dateString === "N/A") {
      return "N.A";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    //console.log(`${day}-${month}-${year}`);
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    //const orderIdObject = { ref: orderId };
    const orderIdObject = { ref: orderId };

    const fetchData = async () => {
      // get the data from the api
      //console.log(`order Object: ${orderIdObject}`);

      // switch (orderInfo[0].status) {
      //   case "Scheduled":
      //     setOrderMessageTitle("Thank you!");
      //     setOrderMessage("It's on the way!");
      //     setOrderStatus("Scheduled");
      //     break;
      //   case "Completed":
      //     setOrderMessageTitle("Thank you!");
      //     setOrderMessage("It's Delivered ");
      //     setOrderStatus("Completed");
      //     break;
      //   case "Cancelled":
      //     setOrderMessageTitle("We are sorry :( ");
      //     setOrderMessage("It's Cancelled");
      //     setOrderStatus("Cancelled");
      //     break;
      //   case "Pending":
      //     setOrderMessageTitle("Thank you!");
      //     setOrderMessage("We already received");
      //     setOrderStatus("Pending");
      //     break;
      //   default:
      //     break;
      // }

      const orderDetails = await OrdersAPI().orderTrackingInfo(orderIdObject);
      // convert the data to json
      //console.log(orderDetails.data.BuildingName);
      // set state with the result

      if (orderDetails.statuscode === 404) {
        console.log("Order Not Found");
        alert("Order Not Found");
        return;
      }

      setOrderDetails(orderDetails);

      //console.log(orderDetails);

      // SubTotal: SubTotal,
      // TaxTotal: TaxTotal.toFixed(2),
      // Discount: Discount.toFixed(2),
      // ShippingTotal: ShippingTotal.toFixed(2),
      // OrderTotal: NetTotal.toFixed(2),
      // console.log(orderDetails.data.SubTotal);
      // console.log(orderDetails.data.TaxTotal);
      // console.log(orderDetails.data.OrderTotal);

      //console.log(orderDetails.paymentState);
      //setting up the Products from the order

      setCustomerAddress({
        buildingName: orderDetails.data.BuildingName,
        flatNumber: orderDetails.data.FlatNumber,
        landmark: orderDetails.data.Landmark,
        address: orderDetails.data.CustomerAddress,
        driverName: orderDetails.data.driverName,
        driverMob: orderDetails.data.driverMob,
      });

      //   status: 'Pending',
      // orderDate: '2024-11-17T00:00:00',
      // scheduleDate: 'N/A',
      // actualdeliveryDate: 'N/A',
      // deliveryLatitude: 0,
      // deliveryLongitude: undefined
      setTrackDetails({
        orderStatus: orderDetails.data.orderTrackingDetails.status,
        orderDate: formatDateToMMDDYYYY(
          orderDetails.data.orderTrackingDetails.orderDate
        ),
        orderScheduleDate: formatDateToMMDDYYYY(
          orderDetails.data.orderTrackingDetails.scheduleDate
        ),
        actualDeliveryDate: formatDateToMMDDYYYY(
          orderDetails.data.orderTrackingDetails.actualdeliveryDate
        ),
        latitude: orderDetails.data.orderTrackingDetails.deliveryLatitude,
        longitude: orderDetails.data.orderTrackingDetails.deliveryLongitude,
      });

      setOrderSummary({
        subTotal: orderDetails.data.SubTotal,
        taxTotal: orderDetails.data.TaxTotal,
        discount: orderDetails.data.Discount,
        shippingTotal: orderDetails.data.ShippingTotal,
        orderTotal: orderDetails.data.OrderTotal,
      });

      //console.log(orderDetails.data.allItems);
      setProducts(orderDetails.data.allItems);

      // Check if the payment was successful
      if (orderDetails.paymentState === true) {
        setPaymentStatus("Payment Successfully Processed");
      } else {
        setPaymentStatus("Payment Failed");
      }
    };

    fetchData();
  }, [orderId]);

  const [showDialog, setShowDialog] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("Pending");
  const latitude = 25.3121287; // Example latitude
  const longitude = 55.5818015; // Example longitude

  return (
    <>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center md:mb-9 h-16 md:h-32">
          <span className="text-2xl text-textWhite font-medium md:text-5xl lg:text-7xl  ">
            Order Tracking
          </span>
        </div>
      </div>

      <main className="relative lg:min-h-full">
        <div className="h-80 items-stretch justify-end flex overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <img
            alt="TODO"
            src="../../images/zulalTrackingOrder.png"
            className="object-contain object-top"
          />
        </div>

        <div>
          <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-4 xl:gap-x-24">
            <div className="lg:col-start-2">
              <h1 className="text-sm font-medium text-green-600">
                Payment Status : {paymentStatus}
              </h1>
              <h1 className="text-sm font-medium text-green-600">
                Order Status : {trackDetails.orderStatus}
              </h1>

              <div className="grid grid-cols-2">
                <div>
                  <h1 className="text-C12 text-cool-gray-400">
                    Order Date : {trackDetails.orderDate}
                  </h1>
                  <h1 className="text-C12 text-cool-gray-400">
                    Schedule date : {trackDetails.orderScheduleDate}
                  </h1>
                  <h1 className="text-C12 text-cool-gray-400 mb-4">
                    Actual Delivery Date : {trackDetails.actualDeliveryDate}
                  </h1>
                </div>
                <div>
                  <h1 className="text-C12 text-cool-gray-400">
                    Driver Name : {customerAddress.driverName}
                  </h1>
                  <h1 className="text-C12 text-cool-gray-400">
                    Driver Mobile : {customerAddress.driverMob}
                  </h1>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <div>
                <dl className="mt-2 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                  <div>
                    <dt className="font-poppins text-C12 text-gray-900">
                      Shipping Address
                    </dt>
                    <dd className="mt-2 mb-2 font-poppins text-C12 text-gray-900">
                      <address className="not-italic">
                        <span className="block">
                          {customerAddress.flatNumber +
                            " , " +
                            customerAddress.buildingName}
                        </span>
                        <span className="block">
                          {customerAddress.landMark}
                        </span>
                        <span className="block">{customerAddress.address}</span>
                      </address>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900"></dt>
                    <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                      <div className="flex-none">
                        {/* SVG and VISA information removed */}
                      </div>
                      <div className="flex-auto">
                        <p className="text-gray-900"></p>
                        <p></p>
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
              <div>
                <hr />
              </div>

              <div>
                <UserTrackingMap latitude={latitude} longitude={longitude} />
              </div>
              <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Thanks for ordering
              </p>

              <p className="mt-4 text-base text-gray-500">
                We’ve received your order and are already preparing it for
                shipment. Thank you for choosing Zulal! Your trust means a lot
                to us, and we’re committed to providing you with quality
                products and services. <br />
                If you have any questions or need support, please don’t hesitate
                to reach out.
              </p>

              <dl className="mt-8 text-sm font-medium">
                <dt className="text-gray-900">
                  Order Number number : {getLastNCharacters(orderId, 6)}
                </dt>
                <dd className="mt-2 text-indigo-600">{}</dd>
              </dl>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
              >
                {products &&
                  products.map((product) => (
                    <li key={product.itemCode} className="flex space-x-6 py-6">
                      <img
                        alt={""}
                        src={product.src}
                        className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                      />
                      <div className="flex-auto space-y-1">
                        <h3 className="text-gray-900">
                          <a href={product.href}>{product.name}</a>
                        </h3>
                        <p>{product.uom}</p>
                      </div>
                      <p className="flex-none font-medium text-gray-900">
                        {product.quantity} x {product.price} ={" "}
                        {(product.quantity * product.price).toFixed(2)}
                      </p>
                    </li>
                  ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">{orderSummary.subTotal} AED</dd>
                </div>

                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">
                    {orderSummary.shippingTotal} AED
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Taxes</dt>
                  <dd className="text-gray-900">{orderSummary.taxTotal} AED</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">{orderSummary.orderTotal} AED</dd>
                </div>
              </dl>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <a
                  href="https://www.zulal.ae/productsrange"
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Continue Shopping
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default OrderTracking;
