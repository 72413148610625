import React, { useEffect, useState } from "react";

import { useContext } from "react";
import { CartContext } from "../../context/cart.context";
import { UserContext } from "../../context/user.context";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import geoData from "../../utils/Data/GeoLocations.json";
import { NetworkInt } from "../../utils/NetworkInt";

import { Link, useLocation } from "react-router-dom";

import CheckoutItemCard from "./CheckoutItemCard";
import axios from "axios";

export const CheckOut = () => {
  const { cartItems, shippingCharges, cartGTotal, cartCount, cartTotal } =
    useContext(CartContext);
  const [userCityName, setUserCityName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [fiveGallonNotice, setFiveGallonNotice] = useState(false);

  const { currentUser, sonicAccessToken } = useContext(UserContext);
  const customerToken = sonicAccessToken;

  const [disableButton, setDisableButton] = useState(false);

  let userDetails = useState({});

  const [merchantDefinedData, setMerchantDefinedData] = useState([]);

  //console.log(cartItems);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  function merchantAttributeData() {
    let result = {};

    result["customerCode"] = `${currentUser.customerCode}`;
    cartItems.forEach((item, index) => {
      result[
        `item${index}`
      ] = `id: ${item.id},uom: ${item.uom},price: ${item.price}, quantity: ${item.quantity}, discount: ${item.discount}`;
    });

    //console.log(result);
    setMerchantDefinedData(result);
  }

  useEffect(() => {
    merchantAttributeData();
    cartItems.forEach((item, index) => {
      //console.log(item.id);

      if (item.id === "9000464") {
        setFiveGallonNotice(true);
      } else {
        setFiveGallonNotice(false);
      }
    });
  }, [cartItems]);

  useEffect(() => {
    if (customerToken !== null) {
      const params = { customerToken: customerToken };
      getCustomerDetails(params);
    }
  }, [customerToken]);

  const getCustomerDetails = async (params) => {
    const objCustomerDetails = CallCenterAPI().getCustomerDetails(params);
    objCustomerDetails
      .then((detailres) => {
        userDetails = structuredClone(detailres.data);

        //console.log("Data loaded from Zulal Credits ");
        //console.log(userDetails);
        setUserAddress(userDetails.addresses[0].address);

        const foundCity = geoData.cities.find(
          (city) =>
            city.CityID === userDetails.cityID &&
            city.StateID === userDetails.stateID
        );

        // const foundCity = data.cities.find(
        //   (city) => city.CityID == userDetails.cityID && city.StateID == 1
        // );

        if (foundCity) {
          setUserCityName(foundCity.CityName);
        } else {
          console.log("city not found");
          setUserCityName("NA");
        }

        //console.log(foundCity);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  function generateUniqueOrder() {
    return Math.floor(Math.random() * Date.now()).toString(16) + "WEB";
  }

  const openInNewTab = (url) => {
    window.open(url, "_self", "noreferrer");
  };

  function valuesfromCart() {
    let totalPrice = 0;
    let gTotal = 0;
    cartItems.forEach((cartItem) => {
      const price = parseFloat(cartItem.price);
      const itemPrice = price * parseInt(cartItem.quantity);
      const taxAmount = calculateTaxAmount(
        price,
        parseInt(cartItem.quantity),
        0.05
      );
      console.log(taxAmount);
      totalPrice += itemPrice + taxAmount;
    });

    const t = Math.round(totalPrice * 100) / 100;

    //console.log(`totalPrice: ${t}`);
  }

  function addValues(val1, val2) {
    const total = parseFloat(val1) + parseFloat(val2);
    return total.toFixed(2);
  }

  function multipleOfValues(val1, val2) {
    const total = parseFloat(val1) * parseFloat(val2);
    return total.toFixed(2);
  }

  function CalculateMinorValues() {
    let grandTotal = 0;
    let minorValue = 0;

    cartItems.forEach((cartItem) => {
      const price = parseFloat(
        multipleOfValues(cartItem.price, cartItem.quantity)
      );

      const taxAmount = parseFloat(
        calculateTaxAmount(cartItem.price, cartItem.quantity, 0.05)
      );

      const itemValueWithTax = addValues(
        Math.round(price * 100) / 100,
        Math.round(taxAmount * 100) / 100
      );

      grandTotal = parseFloat(addValues(grandTotal, itemValueWithTax));
    });

    minorValue = (Math.round(grandTotal * 100) / 100) * 100;

    if (process.env.NODE_ENV !== "production") {
      console.log("Development mode enabled");
      console.log(minorValue);
    }

    return parseInt(minorValue);
  }

  const handlSubmit = async (e) => {
    e.preventDefault();

    if (
      currentUser.customerCode === "" ||
      currentUser.customerCode === undefined
    ) {
      alert("Please Login again to Continue, we encountered a problem !!!");
      return;
    }

    const env_var = process.env.NODE_ENV;

    if (env_var === "development") {
      console.log("Development mode enabled");
      console.log(CalculateMinorValues());
      console.log("Cusotmer Code : " + currentUser.customerCode);
    }
    let userOrder;

    if (env_var === "development") {
      userOrder = {
        action: "PURCHASE",
        amount: {
          currencyCode: "AED",
          value: CalculateMinorValues(),
        },
        emailAddress: currentUser.email,
        language: "en",
        merchantAttributes: {
          redirectUrl: "https://qa.zulal.ae:3000/orderconfirmation",
          skipConfirmationPage: "true",
          merchantOrderReference: currentUser.customerCode,
        },
        billingAddress: {
          firstName: currentUser.outletName.split(" ")[0],
          lastName: currentUser.outletName.split(" ")[1],
          address1: userAddress,
          city: userCityName,
          countryCode: "UAE",
        },
        merchantDefinedData,
      };
    } else {
      userOrder = {
        action: "PURCHASE",
        amount: {
          currencyCode: "AED",
          value: CalculateMinorValues(),
        },
        emailAddress: currentUser.email,
        language: "en",
        merchantAttributes: {
          redirectUrl: "https://www.zulal.ae/orderconfirmation",
          skipConfirmationPage: "true",
          merchantOrderReference: currentUser.customerCode,
        },
        billingAddress: {
          firstName: currentUser.outletName.split(" ")[0],
          lastName: currentUser.outletName.split(" ")[1],
          address1: userAddress,
          city: userCityName,
          countryCode: "UAE",
        },
        merchantDefinedData,
      };
    }
    const tokenResponse = NetworkInt().getAccessToken();
    //console.log(tokenResponse)

    tokenResponse
      .then((response) => {
        const accessToken = response.access_token;

        if (env_var === "development") {
          console.log("development mode enabled");
          console.log(accessToken);
          console.log(userOrder);
        }

        const params = { accessToken: accessToken, userOrder: userOrder };

        const orderResponse = NetworkInt().createOrder(params);

        orderResponse
          .then((response) => {
            if (env_var === "development") {
              console.log("development mode enabled");
              console.log(response.data);
              console.log(response.data.payByLinkUrl);
            }
            //const orderReference = response.data.orderReference;

            // // Sonic Save Order
            // if (saveSonicOrder(orderReference) === true) {
            //   if (env_var === "development") {
            //     console.log("Sonic Order Created");
            //   }
            // }

            // // Cloud Save Order
            // saveOrder(currentUser.customerCode, orderReference, cartItems)
            //   .then((res) => {
            //     if (env_var === "development") {
            //       console.log("Save Order in Firebase");
            //       console.log(res);
            //     }
            //   })
            //   .catch((err) => {
            //     console.log("Got Error by saving Order in FS");
            //     console.log(err);
            //   });

            alert("We will redirect you to Payment Gateway Page");
            openInNewTab(response.data.payByLinkUrl);
          })
          .catch((err) => {
            if (env_var === "development") {
              console.log("Error While Saving Order In NI API");
              console.log(err);
            } else if (env_var === "production") {
              console.log("Checkout ERROR - Code:NI-API-SAV");
              //checkout Network International API saveing error
            }
          });
      })
      .catch((err) => {
        console.log("Unable to get Token from NI API");
        console.log(err);
      });
  };

  async function saveSonicOrder(orderReference) {
    //console.log(orderReference);

    // const orderID = generateUniqueOrder();
    // console.log(orderID);
    // // const sonincRes = saveSonicOrder(orderID);
    // // console.log(sonincRes);
    // if (saveSonicOrder(orderID) === true) {
    //   console.log("Done");
    // }
    // saveOrder(currentUser.customerCode, orderID, cartItems)
    //   .then((res) => {
    //     console.log("Save Order in Firebase");
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log("Got Error by saving Order in Firebase");
    //     console.log(err);
    //   });

    try {
      const allItems = [];
      let totalPrice = 0;
      cartItems.forEach((cartItem) => {
        const price = Math.round(parseFloat(cartItem.price) * 100) / 100;
        const taxAmount = parseFloat(
          calculateTaxAmount(price, parseInt(cartItem.quantity), 0.05)
        );

        const product = {
          itemCode: cartItem.id.toString(),
          uom: cartItem.uom,
          quantity: parseInt(cartItem.quantity),
          price: price,
          discountAmount: 0,
          taxAmount: Math.round(taxAmount * 100) / 100,
          isFOC: false,
        };
        totalPrice += price;
        allItems.push(product);
      });

      //console.log(`totalPrice: ${totalPrice}`);
      //console.log(allItems);

      const sOrder = {
        OrderNumber: orderReference,
        OrderDate: dateFormat(Date.now()),
        DeliveryDate: dateFormat(Date.now(), 1),
        DeliveryValue: 0,
        allItems: allItems,
      };

      //console.log(sOrder);

      const params = { order: sOrder, customerToken: customerToken };

      if (customerToken !== "") {
        //console.log("Condition true");
        CallCenterAPI()
          .sendSonicOrder(params)
          .then((response) => {
            //console.log(response);
          })
          .catch((error) => {
            //console.log(error);
          });
      }

      //console.log(params);
      return true;
    } catch (error) {
      return false;
    }
  }

  function SaveTempOrder(orderReference = "ndnj239090") {
    const stoURL = "http://localhost:5000/api/v1/savecartitems";

    const allItems = [];
    let totalPrice = 0;
    cartItems.forEach((cartItem) => {
      const price = Math.round(parseFloat(cartItem.price) * 100) / 100;
      const taxAmount = parseFloat(
        calculateTaxAmount(price, parseInt(cartItem.quantity), 0.05)
      );

      const product = {
        itemCode: cartItem.id.toString(),
        uom: cartItem.uom,
        quantity: parseInt(cartItem.quantity),
        price: price,
        discountAmount: 0,
        taxAmount: Math.round(taxAmount * 100) / 100,
        isFOC: false,
      };
      totalPrice += price;
      allItems.push(product);
    });

    //console.log(`totalPrice: ${totalPrice}`);
    //console.log(allItems);

    const sOrder = {
      OrderNumber: "orderReference",
      OrderDate: dateFormat(Date.now()),
      DeliveryDate: dateFormat(Date.now(), 1),
      DeliveryValue: 0,
      allItems: allItems,
    };

    //console.log(sOrder);

    axios
      .post(stoURL, { data: sOrder })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function dateFormat(dateString, ddplus) {
    var dateObject = new Date(dateString);

    var year = dateObject.getFullYear();
    var month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    var day = dateObject.getDate().toString().padStart(2, "0");
    var nextday = (dateObject.getDate() + 1).toString().padStart(2, "0");

    // Format the date as yyyy-mm-dd
    var formattedDate = `${year}-${month}-${day}`;

    if (ddplus === 1) {
      formattedDate = `${year}-${month}-${nextday}`;
      //console.log(formattedDate);
      return formattedDate;
    } else {
      formattedDate = `${year}-${month}-${day}`;
      //console.log(formattedDate);
      return formattedDate;
    }
  }

  function calculateTaxAmount(price, quantity, amount) {
    const itemvalue = parseFloat(price) * parseInt(quantity);
    const t = parseFloat(itemvalue) * amount;
    return parseFloat(t);
  }

  return (
    <div>
      <div className="mx-auto mr-4 ml-4 mb-28  ">
        <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
          CheckOut - Order Summary
          <p className="text-darkTextColor font-poppins text-C12">
            Update your{" "}
            <Link to="/user/address" state={{ PageTitle: "Address" }}>
              Address &rarr;
            </Link>
          </p>
          <Link
            to="/productsrange"
            type="button"
            state={{ PageTitle: "Shop" }}
            className="font-medium text-base text-primary hover:text-primaryHover mt-5 items-center justify-center "
          >
            Continue to Shop or Modify Your order
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="max-w-7xl">
          {cartCount > 0 ? (
            <div>
              {cartItems.map((product) => (
                <CheckoutItemCard product={product} />
              ))}

              <div className="">
                <h2 className="sr-only">Order summary</h2>

                <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                  <div className="flex justify-between">
                    <dt>Subtotal</dt>
                    <dd className="text-gray-900">{cartTotal.toFixed(2)}</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt>Taxes</dt>
                    <dd className="text-gray-900">
                      {multipleOfValues(cartTotal, 0.05)}
                    </dd>
                  </div>
                  <div className="flex justify-between">
                    <dt>Shipping</dt>
                    <dd className="text-C12 text-red-500 max-w-20 w-40 font-poppins">
                      {cartGTotal < 75 ? `Minimum order 75 AED` : ""}
                    </dd>
                    <dd className="text-gray-900 ">
                      {shippingCharges.toFixed(2)}
                    </dd>
                  </div>
                  <div className="flex justify-between border-t border-cool-gray-400 pt-6 text-cool-gray-700">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">{cartGTotal.toFixed(2)}</dd>
                  </div>

                  {fiveGallonNotice && (
                    <div className="flex justify-start border-t border-cool-gray-400 pt-6 text-cool-gray-700">
                      <dt className="text-C12 bg-red-400 text-textWhite p-2">
                        Note:
                      </dt>
                      <dd className="text-C11 bg-yellow text-red-600 p-2">
                        If you're a new customer OR don't have empty bottles for
                        exchange, a refundable security deposit of 20 AED per
                        bottle is required for 5 Gallon Bottles.
                      </dd>
                    </div>
                  )}
                </dl>

                <div className="mx-auto w-full max-w-lg mt-4">
                  {cartGTotal < 75 ? (
                    <button
                      type="button"
                      disabled
                      className="flex w-full items-center justify-center rounded-md mt-5
                    border border-transparent cursor-not-allowed
                    bg-darkLight hover:bg-darkLight
                    px-6 py-3 text-base font-medium text-textWhite hover:text-cool-gray-700 shadow-sm "
                    >
                      Pay Your Order {cartGTotal.toFixed(2)} AED
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handlSubmit}
                      className="flex w-full items-center justify-center rounded-md mt-5
                                border border-transparent 
                                bg-secondary hover:bg-secondaryHover
                                px-6 py-3 text-base font-medium text-textWhite hover:text-cool-gray-700 shadow-sm "
                    >
                      <span className="">
                        Pay Your Order {cartGTotal.toFixed(2)} AED
                      </span>
                    </button>
                  )}

                  <Link
                    to="/productsrange"
                    type="button"
                    state={{ PageTitle: "Shop" }}
                    className="font-medium text-primary hover:text-primaryHover mt-5 items-center justify-center "
                  >
                    Continue to Shop or Modify Your order
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-md">
              <span className="text-2xl text-center items-center justify-center">
                Your Cart is empty, please{" "}
                <Link
                  to="/productsrange"
                  type="button"
                  state={{ PageTitle: "Shop" }}
                  className="font-medium text-base text-primary hover:text-primaryHover mt-5 items-center justify-center "
                >
                  Continue to Shop
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </span>
              <img src="../../images/emptycart.jpg" className="" alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
