import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon, UserIcon } from "@heroicons/react/24/solid";
import { Link, NavLink } from "react-router-dom";

// Icons
import { ReactComponent as Profile } from "../../images/icons/profile.svg";
import { ReactComponent as Address } from "../../images/icons/location.svg";
import { ReactComponent as Security } from "../../images/icons/security-user.svg";
import { ReactComponent as Orders } from "../../images/icons/orders.svg";
import { ReactComponent as Payments } from "../../images/icons/payments.svg";
import { ReactComponent as ZulalCredits } from "../../images/icons/credits.svg";
import { ReactComponent as Logoff } from "../../images/icons/logout.svg";

import { useContext } from "react";
import { UserContext } from "../../context/user.context";
import { useState } from "react";

import {
  UilCreateDashboard,
  UilUser,
  UilShieldCheck,
  UilUserLocation,
  UilShoppingCart,
  UilInvoice,
  UilWallet,
  UilSignout,
  UilCreditCard,
} from "@iconscout/react-unicons";

import NavData from "../../utils/Data/UserNavData.json";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserMenu() {
  const { currentUser } = useContext(UserContext);

  // console.log(`currentUser.isloggedIn : ${currentUser.isloggedIn}`);

  // console.log(`Sonic Access Token from User MEnu : ${sonicAccessToken}`);
  // console.log(`customerToken : ${sessionStorage.getItem("customerToken")}`);

  //console.log(currentUser.phoneNo);
  //console.log(currentUser.isloggedIn);

  if (currentUser.isloggedIn === true) {
    return (
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="inline-block rounded-full bg-primary h-8 w-8 xl:w-10 xl:h-10 text-textWhite  hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-secondary">
            <UserIcon className="mx-auto w-6 h-6" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-4 z-10 mt-2 w-max-w-sm origin-top-right divide-y divide-cool-gray-300 rounded-md bg-textWhite 
                                  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-4 py-3">
              <p className="text-sm">Signed in as</p>
              <p className="truncate text-sm font-medium text-gray-900">
                {`${currentUser.outletName}!`}
              </p>
            </div>
            <div className="py-1">
              {NavData.map((item) => {
                return (
                  item.active && (
                    <NavLink
                      to={`../user/${item.path}`}
                      state={{ PageTitle: item.title }}
                      className={classNames(
                        item.active ? "text-primaryHover" : "text-primary",
                        "flex flex-row items-center px-4 py-1 text-base gap-2 font-poppins hover:text-secondary"
                      )}
                    >
                      {item.title === "Dashboard" ? (
                        <UilCreateDashboard className="text-primaryHover" />
                      ) : item.title === "Profile" ? (
                        <UilUser className="text-primaryHover" />
                      ) : item.title === "Address" ? (
                        <UilUserLocation className="text-primaryHover" />
                      ) : item.title === "Security" ? (
                        <UilShieldCheck className="text-primaryHover" />
                      ) : item.title === "Zulal Wallet" ? (
                        <UilWallet className="text-primaryHover" />
                      ) : item.title === "Orders" ? (
                        <UilShoppingCart className="text-primaryHover" />
                      ) : item.title === "Invoices" ? (
                        <UilInvoice className="text-primaryHover" />
                      ) : item.title === "Log Out" ? (
                        <UilSignout className="text-primary" />
                      ) : item.title === "Partners Card" ? (
                        <UilCreditCard className="text-primary" />
                      ) : (
                        ""
                      )}
                      {item.title}
                    </NavLink>
                  )
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  } else {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-block rounded-full bg-darkLight h-8 w-8 xl:w-10 xl:h-10 text-textWhite  hover:bg-darkHover focus:outline-none focus:ring-2 focus:ring-darkVeryLight">
            <NavLink
              to={"/login"}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <UserIcon className="mx-auto w-6 h-6" />
            </NavLink>
          </Menu.Button>
        </div>
      </Menu>
    );
  }
}
