import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../context/products.context";

import ProductCard1 from "./ProductCard1";
import { UserContext } from "../../context/user.context";
import { PromoProductsByToken } from "../../utils/ProductsApi";

export const ProductsRange = () => {
  const { products, getMyProductsByCustomerGroup } =
    useContext(ProductsContext);

  const { currentUser, sonicAccessToken } = useContext(UserContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (sonicAccessToken != null) {
  //     const prod = getMyProductsByCustomerGroup(sonicAccessToken);
  //   }
  // }, []);

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchData = async () => {
      try {
        setLoading(true);
        if (sonicAccessToken != null) {
          // console.log("************");
          // console.log(sonicAccessToken);
          // console.log("**********");
          // const response = await getMyProductsByCustomerGroup(sonicAccessToken);
          // console.log("******Response******");
          // console.log(response);
          // console.log("******Response******");

          // const responseData = await PromoProductsByToken(sonicAccessToken);
          // console.log(responseData);

          // setData(responseData.data);

          getMyProductsByCustomerGroup(sonicAccessToken);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, []); // Dependency array, adjust as needed

  return (
    <div className="bg-white">
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-textWhite font-medium">
            Zulal Store
          </span>
        </div>
      </div>

      <div className="flex mx-auto items-center justify-center max-w-2xl px-4 mb-8 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
        <div></div>
        <div className="mb-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map(
            (product) =>
              product.instock && (
                <ProductCard1 key={product.id} product={product} />
              )
          )}
        </div>
      </div>
    </div>
  );
};
